<!--  -->
<template>
  <div class="container">
    <div class="apply-box">
      <div class="inner-cont">
        <div>
          亲爱的蜜蛋店主，为了让您更安全、便捷地在“蜜蛋店铺“小程序，“团购特卖”模块中销售商品并完成资金结算，我们强烈建议您前往“蜜蛋管理”小程序申请成为微信支付二级商户。请点击以下按钮前往申请：
        </div>
        <div class="operate">
          <van-button round type="default" color="#FFB5BB" @click="onUpgradation()">升级二级商户</van-button>
        </div>
      </div>
    </div>
    <div class="desc-cont">
      <div class="item">
        <div class="subtitle">什么是二级商户？</div>
        <div class="detail">
          二级商户是<span>微信支付</span>专为电商行业场景打造的支付、结算解决方案。蜜蛋店主入驻<span>微信支付</span>成为二级商户。
        </div>
      </div>
      <div class="item">
        <div class="subtitle">二级商户有什么优势？</div>
        <div class="detail">
          交易资金由<span>微信支付官方</span>进行结算,<span>蜜蛋平台</span>不参与任何结算,资金安全有保障。
        </div>
        <div class="detail">每笔订单支付成功后,资金即刻到账店主“<span>微信支付商家助手</span>”小程序（注意：不能到账个人微信余额）,消费者确认收货后,资金解冻,随时可自提银行卡</div>
      </div>
    </div>
    <van-popup v-model="showUpgradation" round>
      <div class="code-list">
        <div class="code-item">
          <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230614155235727.jpg" />
          <div class="prompt">长按二维码识别进入</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      showUpgradation: false,
      isInApp: false,
    }
  },
  created() {
    let { token } = this.$route.query;
    if (token) this.isInApp = true;
  },
  methods: {
    //点击升级二级商户
    onUpgradation() {
      if (!this.isInApp) this.showUpgradation = true;
    }
  }
}
</script>
<style lang='scss' scoped>
.container {
  height: 100%;
  overflow-y: auto;
  padding-top: 20px;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #FFB5BB, #FFE0E3);

  .apply-box {
    padding-bottom: 30px;
    background: url("http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230612105152922.png") no-repeat;
    background-size: 100% 100%;

    .inner-cont {
      padding: 25px 50px;
      font-size: 14px;
      letter-spacing: 1px;

      .operate {
        text-align: center;

        .van-button {
          height: 34px;
          font-size: 12px;
        }
      }
    }
  }

  .desc-cont {
    margin-top: -10px;
    padding: 50px 50px 40px;
    background: url("http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230612111206078.png") no-repeat;
    background-size: 100% 100%;

    .item {
      font-size: 14px;
      letter-spacing: 0.5px;

      .subtitle {
        color: #FFB5BB;
        padding: 5px 0;
      }

      .detail {
        text-indent: 20px;
        padding-bottom: 5px;

        span {
          color: #000;
          font-weight: bold;
        }
      }
    }
  }
}

.code-list {
  width: 300px;
  padding: 15px;
  box-sizing: border-box;

  .code-item {
    text-align: center;

    img {
      max-width: 90%;
    }

    .prompt {
      color: #999;
      font-size: 14px;
      padding-top: 8px;
    }
  }
}
</style>